jQuery.extend({

    footerModule: function(holder){

        // PUBLIC
        var _this    = this;

        var _private = {

            // PRIVATE OBJECTS
            holder : _this,
            menuItem : null,
            textWidgetPar : null,


            // PRIVATE FUNCTIONS
            setup:function() {
              _private.menuItem = $(holder).find('.menu-item a').addClass('footer__widget_txt');
              _private.menuItem = $(holder).find('.textwidget > p').addClass('footer__widget_txt');
            },

            // display:function() {

            // },

            // setProperties:function() {

            // },

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();

        }
            // WINDOW RESIZE
        function doResize() {
            _private.setup();
        }
        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 100);
        });
    }
});
$(function()
{
    $(document).ready(function()
    {
        var footerModule = new $.footerModule($(".footer__module"));
    });
});
jQuery.extend({
	fullWidthSlider: function(holder){
		// PUBLIC
		var _this 				= this;
		var _private = {
			holder : _this,
			slides : null,
			teller : null,
			slider : null,

			setup:function() {
				_private.teller = 0;
				_private.slider = $(holder).find(".full__width__slider--slides");
				$('.full__width__slider--slides > li:first-child').addClass('selected');

				$(holder).find('.full__width__slider--slides>li').each(function(){
					$(holder).find('.slider__dots').append("<li></li>");
				});

				_private.time = $(holder).find('.full__width__slider--slides').attr('timer');

				_private.changeDotColor(_private.teller);
			},
			setProperties:function() {
				$(holder).find("ul.full__width__slider--slides > li").width($(window).width());
				$(holder).find("ul.full-width-slides > li > img").width($(window).width());
				$(holder).find("ul.full__width__slider--slides").width($(holder).find('.full__width__slider--slides').children().length * $(window).width());
			},
			changeDotColor:function(index) {
				$(holder).find("ul.slider__dots li:eq("+_private.teller+")").addClass('dotActive');
			},
			fade:function(){
				$(holder).find(" ul.full__width__slider--slides").delay(_private.time).queue(function(){

					$('.full__width__slider--slides > li').eq(_private.teller).fadeOut(200, function(){
						if(_private.teller < $('.full__width__slider--slides > li').length-1)
							_private.teller++;
						else{
							$(holder).find("ul.slider__dots li").removeClass('dotActive');
							_private.teller = 0;
						}
						_private.changeDotColor(_private.teller);
						$('.full__width__slider--slides > li').eq(_private.teller).fadeIn(200, function(){
							$('.full__width__slider--slides > li').removeClass('selected');
							$(this).addClass('selected');
							_private.fade();

						});
					});
					$(this).dequeue();
				});
			},
			slide:function(val) {
				_private.changeDotColor(_private.teller);
				$(holder).find(" ul.full__width__slider--slides").animate({left: "+=0" }, _private.time, function(){

					if(_private.teller < $('.full__width__slider--slides > li').length-1) {
						$(holder).find("ul.full__width__slider--slides").animate({ left: "-="+$(holder).width() }, 200, function() {

							_private.teller++;
							_private.slide ();
						});
					}
					else {
						$(holder).find("ul.full__width__slider--slides").fadeOut(100, function(){
							$(holder).find("ul.full__width__slider--slides").css('left','0px');
							$(holder).find("ul.full__width__slider--slides").fadeIn(100, function(){
								_private.teller = 0;
								$(holder).find("ul.slider__dots li").removeClass('dotActive');
								_private.slide ();
							});
						});
					}
				});
			}
		};
		// INITIALIZE APPLICATION
		if($(holder).length > 0) {

			_private.setup();
			_private.setProperties();

			// If slider has one slide, disable fade effect
			if ( $("ul.full__width__slider--slides > li").length == 1){
					$('.slider__dots').hide();
			} else {
				_private.fade();
			}


		}
		function doResize() {
		    _private.setProperties();
		}
		var resizeTimer;
		$(window).resize(function() {
		    clearTimeout(resizeTimer);
		    resizeTimer = setTimeout(doResize, 200);
		});
	}
});
$(function()
{
	$(document).ready(function()
	{
		var fullWidthSlider = new $.fullWidthSlider($(".full__width__slider"));
	});
});

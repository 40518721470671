jQuery.extend({

  lazyLoad: function(holder){

    // VERSION : 150714

    // PUBLIC
    var _this         = this;

    var _private = {

        // PRIVATE OBJECTS
        holder                      : null,
        lazyImg                     : null,


         // PRIVATE FUNCTIONS
        setup:function() {
            _private.holder         = holder;
            _private.lazyImg        = _private.holder.find('.lazy');

            _private.lazyImg.Lazy({
                scrollDirection: 'vertical',
                effect: 'fadeIn',
                visibleOnly: true,
            });
        },
    };


    // WINDOW RESIZE
    function doResize() {

    }

    var resizeTimer;
    $(window).resize(function() {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(doResize, 200);

    });

    function initialize()
    {
      _private.setup();


    }
    $(document).ready(function()
    {
      initialize();
    });
  }
});

$(function()
{
  var lazyLoad = new $.lazyLoad($("body"));
});

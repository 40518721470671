jQuery.extend({

	BasicForm: function(holder){

		// VERSION : 150520

		// PUBLIC
		var _this 				= this;

		var _private = {

			// PRIVATE OBJECTS
			form:null,
			selected_val:null,

			// PRIVATE FUNCTIONS
			setup:function() {
				$(holder).find('.input-date').datepicker({
					format: 'dd-mm-yyyy',
				});
			
				_private.form = $("#form-test");

				$(_private.form).find(".hidden-radio").each(function()
				{
					_private.selected_val = $(this).attr("value");

					$(this).closest("div").find("input[type=radio]").each(function()
					{
						if($(this).attr("value") == _private.selected_val)
						{
							$(this).attr("checked", "checked");
						}
					});
				});
			}
		};
		_private.setup();
	}
});
$(function() {
	$(document).ready(function()
	{
		if($("#form-test").children().length > 0)
		{
			var basicForm = new $.BasicForm($("#form-test"));
		}
	});
});
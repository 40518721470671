// jQuery.extend({

// 	Forms: function(holder){

// 		// VERSION : 140904

// 		// PUBLIC
// 		var _this 				= this;

// 		var _private = {

// 			// PRIVATE OBJECTS

// 			// PRIVATE FUNCTIONS
// 			setup:function() {

// 				// Input date

// 				$(holder).find('.input-date').datepicker({
// 					format: 'dd-mm-yyyy',
// 				});
// 			}
// 		};

// 		function initialize()
// 		{
// 			_private.setup();
// 		}
// 		$(document).ready(function()
// 		{
// 			initialize();
// 		});
// 	}
// });

// $(function()
// {
// 	var Forms = new $.Forms($("form"));
// });
jQuery.extend({

    headerMobileMenu: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder : _this,
            Layout__wrapper : null,
            Menu_toggle_nav : null,
            Hassubmenu : null,

            // PRIVATE FUNCTIONS
            setup:function() {
                _private.Layout__wrapper = $(holder).find("#Layout__wrapper");
                _private.Menu_toggle_nav = $(holder).find('.header__menu__toggle__nav > i');
                _private.Hassubmenu = $(holder).find('.header__mobile__menu li.menu-item-has-children a');
            },

            display:function() {

            },

            setProperties:function() {
                $('.header__mobile__menu li.menu-item-has-children > a').closest('i').remove();
                $('.header__mobile__menu li.menu-item-has-children > a').append('<i class="fa fa-chevron-down"></i>');
                $('.header__mobile__menu li.menu-item-has-children a').bind("click", function()  {
                    // _private.subMenu.find('i').addClass('fa-chevron-down');

                    if($(this).hasClass('open'))
                    {
                        $(this).removeClass('open');
                        $(this).find('i').removeClass('fa-chevron-up');
                        $(this).find('i').addClass('fa-chevron-down');
                    }
                    else
                    {
                        $(this).addClass('open');
                        $(this).find('i').removeClass('fa-chevron-down');
                        $(this).find('i').addClass('fa-chevron-up');
                    }
                    $(this).next('.sub-menu').stop().slideToggle("fast");
                });
            },
            toggleNavigation:function(){
                if ($(Layout__wrapper).hasClass('show-nav')) {
                    $(Layout__wrapper).removeClass('show-nav');
                } else {
                    $(Layout__wrapper).addClass('show-nav');

                }
            },
            toggleIcon:function(){
                if ($('.header__menu__toggle__nav > i').hasClass('fa-bars')) {
                    $('.header__menu__toggle__nav > i').removeClass('fa-bars');
                    $('.header__menu__toggle__nav > i').addClass('fa-times');
                } else {
                    $('.header__menu__toggle__nav > i').removeClass('fa-times');
                    $('.header__menu__toggle__nav > i').addClass('fa-bars');
                }
            },
            toggleClick:function(){
                $('.header__menu__toggle__nav').bind('click', function(){
                    _private.toggleNavigation();
                    _private.toggleIcon();
                });
            },
        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.display();
            _private.setProperties();
            _private.toggleClick();
        }
            // WINDOW RESIZE
        function doResize() {
            //_private.updateActions();
            // _private.setProperties();
        }
        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 100);
        });
    }
});
$(function()
{
    $(document).ready(function()
    {
        var headerMobileMenu = new $.headerMobileMenu($(".header__mobile__menu"));
    });
});

jQuery.extend({

    headerMenu: function(holder){

        // PUBLIC
        var _this    = this;

        var _private = {

            // PRIVATE OBJECTS
            holder : _this,
            subMenu : null,
            menuItem : null,


            // PRIVATE FUNCTIONS
            setup:function() {
              _private.menuItem = $(holder).find('.menu-item a').addClass('header__menu__item__txt');
              _private.subMenu = $(holder).find('.sub-menu').addClass('header__menu__sub-menu');
            },

            // display:function() {

            // },

            // setProperties:function() {

            // },

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();

        }
            // WINDOW RESIZE
        function doResize() {
            _private.setup();
        }
        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 100);
        });
    }
});
$(function()
{
    $(document).ready(function()
    {
        var headerMenu = new $.headerMenu($(".header__menu"));
    });
});
jQuery.extend({

    contentBlocks: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder : _this,


            // PRIVATE FUNCTIONS
            setup:function() {


					var Layout__content = $('#Layout__content').find('.layout__bg_content').height('auto');
					setTimeout(function()
					{
					  _private.sidebarHeight = $('#Layout__content').find('.layout__bg_sidebars').outerHeight(true);
					  _private.contentHeight = $('#Layout__content').find('.layout__bg_content').outerHeight(true);
					  _private.highestCol = Math.max($('.layout__bg_sidebars').height(),$('.layout__bg_content').height());
					  _private.content = $('#Layout__content').find('.layout__bg_content');

					  $('.layout__bg_sidebars').height(_private.highestCol);
					  $('.layout__bg_content').height(_private.highestCol);
				  }, 300);



            },
            equalHeights:function()
            {

              setTimeout(function()
              {

                _private.sidebarHeight = $('#Layout__content').find('.layout__bg_sidebars').outerHeight(true);
                _private.contentHeight = $('#Layout__content').find('.layout__bg_content').outerHeight(true);
                _private.highestCol = Math.max($('.layout__bg_sidebars').height(),$('.layout__bg_content').height());

                _private.content = $('#Layout__content').find('.layout__bg_content');

                if ($(window).width() > 991 && !$(_private.content).hasClass('col-lg-12'))
                {
                    $('.layout__bg_sidebars').height(_private.highestCol);
                    $('.layout__bg_content').height(_private.highestCol);

                }else{
                   _private.Layout__sidebar = $('#Layout__content').find('.layout__bg_sidebars').height('auto');
                   _private.Layout__content = $('#Layout__content').find('.layout__bg_content').height('auto');
                }

              }, 1000);



            },
            agendaContentHeights:function()
            {

                var Layout__content = $('#Layout__content').find('.layout__bg_content').height('auto');
                setTimeout(function()
                {
                  _private.sidebarHeight = $('#Layout__content').find('.layout__bg_sidebars').outerHeight(true);
                  _private.contentHeight = $('#Layout__content').find('.layout__bg_content').outerHeight(true);
                  _private.highestCol = Math.max($('.layout__bg_sidebars').height(),$('.layout__bg_content').height());
                  _private.content = $('#Layout__content').find('.layout__bg_content');

                  $('.layout__bg_sidebars').height(_private.highestCol);
                  $('.layout__bg_content').height(_private.highestCol);
                }, 2000);


		  	},
			clickListener:function()
			{
				$('.ai1ec-btn-default').bind('click', function() {
					_private.agendaContentHeights();
				});

				$('.ai1ec-load-view').unbind().bind('click', function()
                {
					_private.agendaContentHeights();
				});

				$('.wpcf7-submit').unbind().bind('click', function() {
					_private.setup();
				});
			}


        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.equalHeights();
			_private.clickListener();

            if( $('.ai1ec-calendar').length > 0 )
            {
                _private.agendaContentHeights();
            }

        }
            // WINDOW RESIZE
        function doResize() {
            _private.equalHeights();
        }
        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 100);
        });
    }
});
$(function()
{
    $(document).ready(function()
    {
        var contentBlocks = new $.contentBlocks($("#Layout__content"));
    });
});
